import { PriceResponse } from './getStipePlans'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import { Database } from '@jobmojito/config/types/supabase'
import { Dispatch, SetStateAction } from 'react'

export async function getStipePlansPublic({
  section,
  merchant_id,
  geo = ''
}: {
  section: string
  merchant_id: string
  geo?: string
}) {
  try {
    const supabase = getClientSupabase()

    const { data: stripePrices, error: stripePricesError } = await supabase.rpc(
      'stripe_prices_public',
      { in_merchant_id: merchant_id, in_section: section, in_geo: geo },
    )

    if (stripePricesError) return ({ error: stripePricesError.message })
    let prices = stripePrices as PriceResponse;

    return({ data: prices } );
  } catch (e) {
    return { error: 'processing problem occured' }
  }

}
