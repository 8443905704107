'use client'
import { Modal, ModalBody, ModalContent, Card, CardFooter } from '@nextui-org/react'
import Image, { StaticImageData } from 'next/image'

export default function ImageModal({
  isOpen,
  onClose,
  title,
  imageFile,
}: {
  isOpen: boolean
  onClose: () => void
  imageFile: StaticImageData
  title: string
}) {

  return (
    <>
      <Modal
        backdrop={'blur'}
        isOpen={isOpen}
        onClose={() => onClose()}
        size={'5xl'}
        placement={'top-center'}
      >
        <ModalContent>
          <ModalBody className={'flex flex-col gap-1'}>

            <Card isFooterBlurred className="w-full h-full col-span-12 sm:col-span-7">
              <Image
                alt="Card background"
                className={"z-0 w-full h-full object-cover "}
                src={imageFile}
                />
              <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                <div className="flex flex-grow gap-2 items-center">
                  <div className="flex flex-col">
                    <p className="text-white">{title}</p>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
