import React from 'react'

export default function CheckList({
  children = undefined,
  className = '',
}: {
  children?: any | undefined
  className?: string
}) {
  return (
    <div className={'flex items-start mb-2 ' + className}>
      <span className="mr-2">
        <svg viewBox="0 0 24 24" width="1.2em" height="1.2em"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10"></circle><path strokeLinecap="round" strokeLinejoin="round" d="m8.5 12.5l2 2l5-5"></path></g></svg>
      </span>
      <span>{children}</span>
    </div>
  )
}
