'use client'
import { Tabs, Tab } from '@nextui-org/tabs'
import { ReactNode } from 'react'
import {useTranslations} from 'next-intl';

// This is mostly an escape for the Next UI Tabs that don't work with server components yet
export default function BillingTabsPublic({
  TopUpPackages,
  TopUpCredits,
}: {
  TopUpPackages: ReactNode
  TopUpCredits: ReactNode
}) {
  const t = useTranslations('component-billing');

  return (
    <div className="flex w-full flex-col">
      <Tabs aria-label="Options" variant="bordered" color='primary' fullWidth={true}>
        <Tab key="topupPackages" title={t("Top-up Packages")}> 
          <div className='min-h-80'>
            {TopUpPackages}
          </div>
        </Tab>
        <Tab key="topupCredits" title={t("Top-up Individual credits")}>
          <div className='min-h-80'>
            {TopUpCredits}
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}
