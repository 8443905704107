export default function isTemporaryEmail(email: string | undefined) {
  // Check if the email is undefined
  if (email === undefined) {
    return false
  }

  // Regular expression to check if the string ends with "_tmp@resumecheck.net"
  const regex = /_tmp@resumecheck\.net$/

  // Check if the email matches the regular expression
  return regex.test(email)
}
