'use client'
import { NavbarItem } from '@nextui-org/navbar'
import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'

export default function LandingMenuLink({
  href,
  children,
  onClick,
  className='',
}: {
  href: string
  children: React.ReactNode
  onClick: () => void
  className?:string
}) {
  const router = useRouter()

  function handleLink(event: any, href: string) {
    event.preventDefault()
    onClick()
    router.push(href)
  }

  return (
    <Link
      onClick={() => handleLink(event, href)}
      className={'text-black'}
      href={href}
    >
      <NavbarItem className={className}>{children}</NavbarItem>
    </Link>
  )
}
