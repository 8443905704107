'use client'

import NextImage, { StaticImageData } from 'next/image'
import { Card } from '@nextui-org/react'
import React, { ChangeEvent, useState } from 'react'
import ImageModal from '@jobmojito/components/core/ImageModal'

export default function ScreenShot({
  title,
  size = 'sm',
  imageFile,
}: {
  title: string
  size?: 'sm' | 'md'
  imageFile: StaticImageData
}) {
  const [confirmationModalShow, setConfirmationModalShow] =
    useState<boolean>(false)

  const calcSize = size === 'md' ? 'max-w-128' : 'max-w-36'
  return (
    <>
      <Card className={'group hover:border-primary border-slate-200 border-2 '+calcSize} shadow='none'>
        <NextImage
          alt="Card background"
          width={size === 'md' ? 1024 : 800}
          className={
            'z-0 w-full h-full object-cover cursor-pointer'
          }
          src={imageFile}
          onClick={() => setConfirmationModalShow(true)}
        />
      </Card>
      <ImageModal
        isOpen={confirmationModalShow}
        onClose={() => setConfirmationModalShow(false)}
        title={title}
        imageFile={imageFile}
      />
    </>
  )
}
