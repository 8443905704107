'use client'
import EmailInput from '@jobmojito/components/core/EmailInput'
import { Modal, ModalContent, ModalHeader, ModalBody } from '@nextui-org/react'
import { useState } from 'react'
import {useTranslations} from 'next-intl';

export default function EmailUpdateModal({
  isOpenCondition,
  onClose,
}: {
  isOpenCondition: boolean
  onClose?: () => void
}) {
  const [isOpened, setIsOpened] = useState(isOpenCondition)

  const handleModalClose = () => {
    setIsOpened(false)
    // call onClose if provided
    if (onClose) {
      onClose()
    }
  }
  const t = useTranslations('component-resume');

  return (
    <>
      <Modal
        size="xl"
        placement="top-center"
        isOpen={isOpened}
        hideCloseButton={true}
        isDismissable={false}
        onClose={() => handleModalClose()}
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">{t('Register your Account')}</ModalHeader>
          <ModalBody className='px-5 pb-5'>
          <div className="flex justify-center text-left text-black">
            <EmailInput
              onUpdateSuccess={() => setIsOpened(false)}
              title={t("We need to create an account for you, please provide your email address")}
              className="max-w-screen-lg"
            />
          </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
