'use client'
import LandingMenuLink from '@jobmojito/components/landing/LandingMenuLink'
import { Button } from '@nextui-org/button'
import {
  NavbarContent,
  NavbarMenu,
  Navbar as NextUiNavbar,
} from '@nextui-org/navbar'
import { NavbarMenuToggle } from '@nextui-org/react'
import NextLink from 'next/link'
import React from 'react'
import { languagesRaw, languagesSorted ,findLanguage } from '@jobmojito/app/utils/data/platform-languages'
import LanguageSelector from '@jobmojito/components/core/LanguageSelector'
import {useTranslations} from 'next-intl';
import { MerchantFeatures } from '@jobmojito/components/core/MerchantConfig'

function MenuLinks({
  isFooter,
  menuLinkOnClick,
  className = '',
  enabledFeatures,
}: {
  isFooter: boolean
  className?: string
  enabledFeatures?: MerchantFeatures
  menuLinkOnClick: () => void
}) {
  const t = useTranslations('component-nav-bar');
  return (
    <>
      <LandingMenuLink className={'font-medium '+className} href={'/#home'} onClick={menuLinkOnClick}>
        {t('Menu public Home')}
      </LandingMenuLink>
      <LandingMenuLink className={'font-medium '+className} href={'/#our_tools'} onClick={menuLinkOnClick}>
        {t('Menu public Our Tools')}
      </LandingMenuLink>
      {enabledFeatures===undefined || ((enabledFeatures?.homepage_pricing_enabled!==undefined) && (enabledFeatures?.homepage_pricing_enabled===true))&&
        <LandingMenuLink className={'font-medium '+className} href={'/#pricing'} onClick={menuLinkOnClick}>
          {t('Menu public Pricing')}
        </LandingMenuLink>
      }
      {enabledFeatures===undefined || ((enabledFeatures?.trivia_enabled!==undefined) && (enabledFeatures?.trivia_enabled===true))&&
        <LandingMenuLink className={'font-medium '+className} href={'/trivia_public'} onClick={menuLinkOnClick}>
          {t('Menu public trivia game')}
        </LandingMenuLink>
      }
      {/* if is footer then show ToS */}
      {isFooter && (
        <LandingMenuLink className='font-medium' href={'/terms_of_service'} onClick={menuLinkOnClick}>
         {t('Menu public Terms Of Service')}
        </LandingMenuLink>
      )}
    </>
  )
}

export default function Navbar({
  merchantLogo,
  isFooter = false,
  enabledFeatures,
  profile,
  languages,
}: {
  merchantLogo: React.JSX.Element
  isFooter?: boolean
  profile?: any
  enabledFeatures?: any
  languages: string[]
}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }
  const t = useTranslations('component-nav-bar');

  return (
    <NextUiNavbar
      maxWidth={'xl'}
      isBordered={!isFooter}
      className={'md:px-10 text-base bg-white w-screen'}
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
    >
      <div className={'w-40 md:w-44 h-full py-2'}>
        <div className="relative w-full h-full">{merchantLogo}</div>
      </div>
      <NavbarContent
        className="hidden lg:flex gap-12 flex-row flex-auto grow"
        justify={'center'}
      >
        <MenuLinks menuLinkOnClick={closeMenu} isFooter={isFooter} enabledFeatures={enabledFeatures} />
      </NavbarContent>

      <div className="flex flex-row grow-0">
        <div className={'flex relative items-center mr-5'}>
          {!isFooter && <LanguageSelector
            languages={languagesSorted.filter((l)=>(languages.includes(l.code)))}
          /> }
        </div>
        <div className={'hidden lg:flex relative items-center gap-5'}>
        {profile ? (
        <>
            <NextLink className={'hidden lg:flex'} href={'/home'}>
              <Button
                size="sm"
                className="bg-primary text-white font-bold px-5"
              >
                {t('Go to App')}
              </Button>
            </NextLink>
        </>):
            <Button
              href={'/login'}
              as={NextLink}
              className={'border-1 h-10'}
              color={'primary'}
              size={'md'}
              radius={'sm'}
            >
              {t('Login & Sign up')}
            </Button>
        }
        </div>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          className="lg:hidden mr-2 h-10"
        />
      </div>

      <NavbarMenu>
        <div className="text-xl flex flex-col items-start mt-5 gap-2">
          <MenuLinks className='text-lg' menuLinkOnClick={closeMenu} isFooter={false} enabledFeatures={enabledFeatures} />
          <div className='flex flex-col gap-5'>
            {profile ? (
            <>
              <NextLink href={'/home'}>
                <Button
                  size="sm"
                  className="bg-primary text-white font-bold px-5"
                >
                  {t('Go to App')}
                </Button>
              </NextLink>
            </>):
              <Button
                href={'/login'}
                as={NextLink}
                className={'border-1 h-10'}
                color={'primary'}
                size={'md'}
                radius={'sm'}
              >
                {t('Login & Sign up')}
              </Button>
            }
          </div>
        </div>
        {/* <ProfileCard profile={profile} /> */}
      </NavbarMenu>
    </NextUiNavbar>
  )
}
